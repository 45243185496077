import React from "react";
import { motion } from 'framer-motion';


export default function Wave() {
    const variants = {
        hidden: { pathLength: 0 },
        visible: { pathLenght: 1,
            transition: {
                delayChildren: 2,
                staggerChildren: 1,
            }
        },

    }

    return (
        <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="1729"
        height="1747"
        fill="none"
        viewBox="0 0 1729 1747"
        className="wave"
        initial="hidden"
        animate="visible"
        variants={variants}
        >
        <mask
            id="mask0_141_34984"
            style={{ maskType: "alpha" }}
            width="1729"
            height="1747"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
        >
            <path fill="#C4C4C4" d="M0 0H1729V1747H0z"></path>
        </mask>
        <g strokeMiterlimit="10" strokeWidth="1.562" mask="url(#mask0_141_34984)">
            <motion.path
            stroke="url(#paint0_linear_141_34984)"
            d="M1751.78 1158.73c47.55-61.07 20.05-156.66-39.69-205.837-59.75-49.211-140.87-62.516-218.13-67.848-77.25-5.368-156.75-4.979-228.87-33.098-149.24-58.227-226.92-219.475-286.35-368.147-59.429-148.673-125.652-311.674-267.555-386.055-111.266-58.3-244.624-48.106-367.5-74.237C129.186-22.089-54.959-189.632-120.369-398.82"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint1_linear_141_34984)"
            d="M1743.01 1177.69c47.54-60.97 20.69-156.34-38.4-206.04-5.27-4.456-10.76-8.593-16.36-12.517-57.18-39.683-130.31-51.962-200.38-57.599-13.53-1.093-27.13-2.009-40.73-2.924-63.87-4.277-128.16-8.701-187.4-32.165-19.21-7.588-37.24-16.912-54.19-27.686-114.38-72.618-179.43-211.577-230.12-341.293a7855.535 7855.535 0 00-11.123-28.411c-55.417-140.734-120.311-286.688-248.826-360.3-36.759-21.048-75.919-35.662-117.787-44.955-49.423-10.991-101.509-14.517-152.588-18.53-34.874-2.746-69.459-5.774-103.438-11.578a557.638 557.638 0 01-47.538-10.254C194.121-2.736 100.932-57.923 26.581-131.411-47.946-205.079-103.96-296.766-134.324-397.03"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint2_linear_141_34984)"
            d="M1734.24 1196.65c47.58-60.9 21.37-156.01-37.08-206.239a226.922 226.922 0 00-16.21-12.657c-56.64-40.176-129.37-53.272-199.21-59.728-13.49-1.235-27.02-2.292-40.59-3.35-63.64-4.952-127.79-9.946-186.81-33.693a323.59 323.59 0 01-54.04-27.898c-114.12-73.079-178.64-211.996-228.253-341.913-3.601-9.433-7.237-18.939-10.873-28.444-54.093-140.896-117.359-285.546-241.541-362.889-36.275-22.612-74.794-39.969-117.506-50.45-50.478-12.396-104.503-15.124-156.78-17.582-35.673-1.684-70.954-3.327-105.734-7.892-16.373-2.163-32.596-5.003-48.772-8.663C187.902 12.006 90.691-44.337 14.513-120.382-61.842-196.571-117.92-291.829-148.245-395.27"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint3_linear_141_34984)"
            d="M1725.5 1215.61c47.58-60.79 22.05-155.68-35.79-206.4-5.16-4.53-10.5-8.81-16.02-12.801-56.14-40.706-128.43-54.617-198.05-61.856-13.42-1.377-26.94-2.613-40.43-3.812-63.43-5.628-127.38-11.191-186.22-35.221-19.06-7.765-36.99-17.194-53.86-28.075-113.83-73.504-177.85-212.379-226.393-342.532-3.529-9.469-7.058-18.973-10.622-28.477-52.769-141.094-114.441-284.546-234.22-365.513-35.828-24.214-73.671-44.24-117.262-55.91-51.531-13.837-107.496-15.732-160.972-16.597-36.508-.623-72.448-.847-108.03-4.208-16.738-1.56-33.395-3.87-50.006-7.07C181.757 26.818 80.521-30.645 2.517-109.247c-78.182-78.747-134.324-177.539-164.646-284.158"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint4_linear_141_34984)"
            d="M1716.73 1234.6c47.58-60.72 22.69-155.35-34.46-206.6-5.13-4.6-10.4-8.87-15.85-12.94-55.59-41.232-127.48-55.924-196.88-63.945-13.38-1.555-26.83-2.897-40.32-4.24-63.2-6.303-127.01-12.471-185.67-36.749a328.629 328.629 0 01-53.67-28.287c-113.58-73.929-177.02-212.797-224.563-343.153-3.458-9.467-6.915-19.006-10.372-28.545-51.445-141.256-111.485-283.76-226.9-368.101-35.343-25.851-72.582-48.546-117.016-61.369-52.622-15.279-110.49-16.34-165.164-15.65-37.307.475-73.944 1.6-110.362-.522-17.102-.994-34.194-2.773-51.276-5.48C175.429 41.664 70.17-16.99-9.662-98.114c-80.008-81.268-136.214-183.632-166.533-293.428"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint5_linear_141_34984)"
            d="M1707.96 1253.56c47.62-60.61 23.36-155.06-33.14-206.8-5.05-4.63-10.29-8.98-15.7-13.08-55.05-41.76-126.54-57.233-195.71-66.072-13.34-1.698-26.76-3.182-40.17-4.667-62.98-6.979-126.64-13.716-185.08-38.277a331.089 331.089 0 01-53.53-28.499c-113.32-74.391-176.23-213.181-222.696-343.773-3.386-9.502-6.736-19.004-10.121-28.578-50.121-141.419-108.491-283.152-219.579-370.725-34.93-27.523-71.458-52.817-116.735-66.864-53.676-16.683-113.484-16.947-169.392-14.7-38.141 1.534-75.473 4.043-112.657 3.162-17.502-.428-34.959-1.64-52.51-3.889C169.212 56.371 59.93-3.404-21.729-87.084c-81.799-83.825-138.106-189.724-168.422-302.698"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint6_linear_141_34984)"
            d="M1699.19 1272.52c47.61-60.54 24.04-154.73-31.85-207-4.98-4.66-10.18-9.08-15.52-13.22-54.55-42.26-125.59-58.579-194.51-68.165-13.3-1.84-26.64-3.502-40.02-5.129-62.76-7.619-126.23-14.961-184.49-39.805a335.922 335.922 0 01-53.35-28.711c-113.06-74.816-175.44-213.6-220.828-344.393a5339.346 5339.346 0 01-9.87-28.611c-48.797-141.581-105.496-282.723-212.259-373.314-34.48-29.231-70.334-57.123-116.49-72.323-54.731-18.124-116.478-17.555-173.584-13.717-38.941 2.597-76.968 6.49-114.953 6.848-17.867.175-35.759-.507-53.78-2.297-114.658-11.5-227.964-72.43-311.45-158.632-83.626-86.347-139.996-195.817-170.309-311.968"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint7_linear_141_34984)"
            d="M1690.46 1291.48c47.64-60.44 24.67-154.4-30.53-207.16-4.95-4.7-10.07-9.16-15.38-13.36-54-42.79-124.64-59.89-193.33-70.3-13.23-1.981-26.57-3.786-39.88-5.554-62.57-8.295-125.86-16.242-183.9-41.333-18.76-8.119-36.51-17.796-53.17-28.924-112.81-75.241-174.643-213.982-218.959-345.012-3.207-9.536-6.414-19.072-9.62-28.643-47.473-141.744-102.39-282.472-204.938-375.939-34.03-31.011-69.21-61.393-116.244-77.818-55.786-19.566-119.472-18.163-177.776-12.768-39.776 3.657-78.463 8.934-117.285 10.532-18.266.742-36.523.626-55.014-.706C156.846 85.96 39.517 23.872-45.796-64.886c-85.453-88.904-141.887-201.909-172.162-321.237"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint8_linear_141_34984)"
            d="M1681.68 1310.47c47.65-60.33 25.35-154.07-29.23-207.36-4.88-4.77-9.97-9.26-15.2-13.5-53.5-43.32-123.67-61.24-192.17-72.42-13.19-2.16-26.46-4.11-39.73-6.02-62.34-8.97-125.48-17.489-183.3-42.863-18.73-8.189-36.37-17.937-53.03-29.1-112.51-75.702-173.851-214.401-217.093-345.633a5228.343 5228.343 0 01-9.369-28.711C896.444 422.957 843.31 282.537 744.94 186.336c-33.579-32.826-68.122-65.7-115.964-83.277-56.84-20.97-122.465-18.77-181.968-11.82-40.574 4.719-79.958 11.416-119.58 14.253-18.631 1.309-37.323 1.759-56.249.886-120.518-5.609-241.834-68.85-329.01-160.13-87.28-91.425-143.778-208.038-174.05-330.507"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint9_linear_141_34984)"
            d="M1672.92 1329.43c47.68-60.26 25.98-153.74-27.92-207.55-4.83-4.81-9.85-9.37-15.02-13.65-52.95-43.81-122.72-62.54-190.99-74.51-13.16-2.3-26.39-4.39-39.62-6.45-62.12-9.64-125.07-18.73-182.71-44.385-18.66-8.296-36.26-18.079-52.85-29.313-112.26-76.127-173.062-214.784-215.265-346.253-3.064-9.534-6.091-19.139-9.118-28.744-44.79-142.068-96.032-282.358-190.298-381.115-33.092-34.677-66.997-70.006-115.718-88.772-57.894-22.412-125.459-19.414-186.195-10.871-41.41 5.815-81.453 13.86-121.912 17.937-18.996 1.911-38.123 2.892-57.519 2.477-123.448-2.646-248.788-67.043-337.79-160.88-89.108-93.947-145.704-214.13-175.937-339.777"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint10_linear_141_34984)"
            d="M1664.15 1348.38c47.68-60.14 26.66-153.44-26.59-207.71-4.77-4.84-9.72-9.44-14.88-13.79-52.45-44.33-121.77-63.89-189.82-76.64-13.09-2.44-26.28-4.67-39.47-6.87-61.9-10.29-124.7-20.01-182.13-45.917a342.282 342.282 0 01-52.66-29.524c-112.01-76.552-172.274-215.167-213.402-346.873a3820.463 3820.463 0 01-8.868-28.777c-43.465-142.23-92.743-282.532-182.977-383.739-32.604-36.564-65.874-74.277-115.473-94.232-58.948-23.852-128.453-20.022-190.388-9.887-42.244 6.876-82.947 16.306-124.208 21.623-19.395 2.477-38.886 4.025-58.752 4.068-126.378.282-255.741-65.271-346.57-161.666-90.935-96.503-147.596-220.222-177.826-349.046"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint11_linear_141_34984)"
            d="M1655.41 1367.34c47.72-60.07 27.34-153.11-25.3-207.91-4.69-4.92-9.6-9.54-14.69-13.93-51.91-44.86-120.83-65.2-188.62-78.73-13.05-2.59-26.2-5-39.32-7.34-61.68-10.96-124.33-21.25-181.57-47.44-18.52-8.47-36.01-18.361-52.52-29.737-111.75-77.014-171.449-215.586-211.538-347.493-2.921-9.568-5.769-19.207-8.617-28.845-42.142-142.393-89.346-282.777-175.657-386.328-32.081-38.485-64.749-78.584-115.191-99.727-60.004-25.257-131.447-20.63-194.58-8.938-43.043 7.937-84.442 18.751-126.504 25.307-19.76 3.08-39.686 5.158-60.022 5.66-129.308 3.21-262.694-63.5-355.35-162.415-92.762-99.025-149.487-226.315-179.714-358.316"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint12_linear_141_34984)"
            d="M1646.64 1386.34c47.71-59.97 27.98-152.79-23.98-208.12-4.65-4.94-9.49-9.64-14.55-14.1-51.36-45.39-119.88-66.54-187.45-80.86-13-2.76-26.09-5.28-39.17-7.76-61.45-11.64-123.92-22.5-180.98-48.97-18.44-8.55-35.89-18.54-52.33-29.952-111.5-77.439-170.661-215.969-209.676-348.113a2655.346 2655.346 0 01-8.366-28.878c-40.818-142.591-85.84-283.162-168.336-388.952-31.557-40.442-63.661-82.854-114.947-105.186-61.057-26.698-134.44-21.237-198.807-7.99-43.878 8.998-85.973 21.231-128.835 28.992-20.16 3.646-40.486 6.29-61.257 7.251C125.719 159.875-11.691 92.01-106.174-9.463-200.763-111.044-257.551-241.87-287.74-377.048"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint13_linear_141_34984)"
            d="M1637.87 1405.3c47.75-59.9 28.65-152.46-22.69-208.31-4.58-4.99-9.38-9.72-14.37-14.25-50.85-45.88-118.93-67.85-186.28-82.95-12.97-2.91-25.98-5.6-39.02-8.19-61.24-12.31-123.55-23.78-180.39-50.5a349.814 349.814 0 01-52.16-30.16c-111.2-77.863-169.862-216.387-207.803-348.732a3828.227 3828.227 0 01-8.116-28.91c-39.493-142.754-82.261-283.583-161.052-391.542-30.96-42.434-62.536-87.16-114.7-110.68-62.112-28.14-137.435-21.845-203-7.006-44.677 10.095-87.467 23.676-131.131 32.677-20.524 4.213-41.25 7.388-62.491 8.843-135.167 9.1-276.6-59.92-372.911-163.915-96.416-104.103-153.268-238.499-183.454-376.855"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint14_linear_141_34984)"
            d="M1629.14 1424.25c47.74-59.79 29.32-152.12-21.37-208.47-4.51-5.02-9.28-9.83-14.19-14.38-50.31-46.42-117.99-69.2-185.11-85.09-12.9-3.05-25.9-5.88-38.91-8.65-61.05-12.95-123.18-25.02-179.8-52.03-18.29-8.72-35.64-18.82-52.01-30.33-110.95-78.293-169.074-216.775-205.976-349.357A2911.39 2911.39 0 01923.909 647c-38.169-142.916-78.61-284.11-153.731-394.165-30.364-44.462-61.413-91.431-114.42-116.14-63.166-29.545-140.392-22.452-207.191-6.057-45.512 11.156-88.962 26.121-133.463 36.361-20.889 4.816-42.05 8.521-63.761 10.434-138.097 12.028-283.554-58.112-381.691-164.664-98.242-106.66-155.159-244.591-185.341-386.125"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint15_linear_141_34984)"
            d="M1620.37 1443.21c47.78-59.72 29.96-151.8-20.04-208.67-4.48-5.09-9.17-9.93-14.05-14.53-49.8-46.94-117.05-70.5-183.91-87.21-12.86-3.19-25.79-6.16-38.76-9.07-60.82-13.63-122.77-26.27-179.21-53.56-18.22-8.79-35.5-18.96-51.83-30.55-110.69-78.748-168.28-217.188-204.107-349.971a2453.49 2453.49 0 01-7.615-29.012c-36.845-143.078-74.816-284.706-146.41-396.754-29.697-46.488-60.289-95.737-114.174-121.634-64.222-30.986-143.387-23.061-211.384-5.109-46.311 12.217-90.457 28.567-135.758 40.047-21.289 5.381-42.814 9.653-64.995 12.025C107.1 204.203-42.38 132.871-142.344 23.798c-100.07-109.181-157.05-250.684-187.23-395.394"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint16_linear_141_34984)"
            d="M1611.6 1462.21c47.78-59.62 30.64-151.51-18.76-208.88-4.4-5.12-9.02-9.99-13.86-14.66-49.26-47.48-116.1-71.82-182.74-89.3-12.82-3.37-25.72-6.49-38.61-9.54-60.6-14.3-122.4-27.55-178.62-55.09-18.15-8.89-35.39-19.1-51.65-30.76-110.44-79.172-167.491-217.569-202.244-350.59-2.526-9.67-4.98-19.34-7.363-29.044-35.522-143.24-70.986-285.302-139.09-399.378-28.993-48.551-59.2-100.008-113.929-127.094-65.276-32.427-146.381-23.668-215.611-4.161-47.146 13.278-91.952 31.048-138.054 43.732-21.654 5.984-43.614 10.786-66.23 13.617-143.956 17.919-297.46-54.533-399.251-166.163C-256.309-76.84-313.353-221.877-343.53-369.766"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint17_linear_141_34984)"
            d="M1602.83 1481.17c47.78-59.55 31.31-151.18-17.43-209.04-4.37-5.16-8.92-10.1-13.72-14.8-48.72-47.97-115.16-73.17-181.57-91.43-12.78-3.51-25.61-6.78-38.47-9.97-60.37-14.98-122.02-28.8-178.02-56.61-18.08-8.97-35.28-19.25-51.51-30.98-110.18-79.596-166.698-217.951-200.376-351.208-2.455-9.67-4.802-19.374-7.113-29.077-34.198-143.403-67.012-285.932-131.77-401.967-28.252-50.648-58.076-104.314-113.648-132.553-66.33-33.832-149.374-24.275-219.803-3.176-47.945 14.339-93.447 33.493-140.386 47.416-22.053 6.551-44.414 11.919-67.499 15.209C94.628 233.866-62.899 160.222-166.517 46.071c-103.724-114.26-160.832-262.869-190.97-413.934"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint18_linear_141_34984)"
            d="M1594.09 1500.12c47.82-59.43 31.96-150.84-16.14-209.23-4.29-5.23-8.81-10.21-13.54-14.95-48.21-48.5-114.21-74.47-180.4-93.52-12.71-3.65-25.53-7.09-38.31-10.39-60.16-15.66-121.62-30.04-177.48-58.14-18-9.04-35.13-19.42-51.32-31.19-109.89-80.06-165.91-218.374-198.513-351.832a1715.832 1715.832 0 01-6.863-29.109c-32.874-143.566-63.002-286.633-124.449-404.591-27.477-52.746-56.952-108.585-113.403-138.049-67.384-35.273-152.367-24.918-223.995-2.227-48.78 15.436-94.977 35.938-142.682 51.137-22.417 7.117-45.178 13.052-68.733 16.8C88.409 248.638-73.105 173.875-178.55 57.131-284.101-59.651-341.272-211.831-371.408-366.073"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint19_linear_141_34984)"
            d="M1585.32 1519.08c47.81-59.36 32.63-150.52-14.81-209.43-4.23-5.27-8.7-10.28-13.36-15.09-47.67-49.03-113.27-75.82-179.23-95.65-12.68-3.79-25.42-7.38-38.21-10.85-59.93-16.3-121.24-31.33-176.88-59.71-17.93-9.14-35.03-19.56-51.14-31.36-109.64-80.49-165.086-218.758-196.686-352.454-2.275-9.703-4.515-19.441-6.647-29.178-31.55-143.727-58.885-287.297-117.129-407.179-26.629-54.841-55.828-112.891-113.158-143.508-68.438-36.714-155.361-25.526-228.187-1.279-49.614 16.497-96.471 38.383-144.977 54.822-22.818 7.72-45.978 14.185-70.003 18.391-152.782 26.737-318.32-49.182-425.592-168.447-107.378-119.339-164.649-275.054-194.746-432.474"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint20_linear_141_34984)"
            d="M1576.55 1538.08c47.85-59.26 33.31-150.19-13.49-209.63-4.19-5.3-8.59-10.39-13.21-15.23-47.17-49.52-112.33-77.13-178.03-97.78-12.64-3.97-25.35-7.66-38.06-11.28-59.71-16.97-120.87-32.57-176.29-61.23-17.86-9.22-34.88-19.71-50.96-31.58-109.381-80.91-164.293-219.172-194.818-353.07a2136.95 2136.95 0 01-6.397-29.21c-30.19-143.89-54.697-287.96-109.809-409.768-25.782-56.938-54.739-117.163-112.876-149.003-69.494-38.119-158.355-26.134-232.415-.295-50.413 17.558-97.966 40.828-147.309 58.507-23.182 8.286-46.778 15.318-71.238 19.982C75.936 278.194-93.625 201.119-202.724 79.296c-109.205-121.86-166.539-281.182-196.633-441.743"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint21_linear_141_34984)"
            d="M1567.78 1557.03c47.85-59.18 33.95-149.89-12.2-209.79a218.76 218.76 0 00-13.03-15.37c-46.63-50.05-111.38-78.47-176.86-99.87-12.6-4.11-25.24-7.98-37.91-11.74-59.53-17.65-120.46-33.81-175.7-62.76-17.79-9.32-34.78-19.85-50.82-31.79-109.122-81.37-163.499-219.556-192.95-353.69a1712.9 1712.9 0 01-6.147-29.244c-28.865-144.087-50.436-288.658-102.487-412.392-24.863-59.068-53.615-121.468-112.631-154.462-70.548-39.56-161.349-26.742-236.607.654-51.248 18.619-99.462 43.309-149.605 62.192-23.547 8.853-47.542 16.451-72.472 21.574C69.719 292.97-103.83 214.74-214.791 90.395-325.823-34.022-383.222-196.879-413.313-360.618"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint22_linear_141_34984)"
            d="M1559.04 1575.99c47.89-59.07 34.63-149.56-10.87-209.99-4.08-5.4-8.38-10.56-12.85-15.51-46.09-50.58-110.44-79.78-175.69-101.99-12.53-4.26-25.16-8.27-37.76-12.17-59.31-18.33-120.09-35.06-175.12-64.29-17.71-9.39-34.66-19.99-50.63-32-108.869-81.8-162.71-219.98-191.087-354.315a1790.734 1790.734 0 01-5.896-29.276c-27.541-144.25-46.069-289.318-95.167-414.98-23.908-61.163-52.491-125.739-112.386-159.957-71.638-41.001-164.343-27.35-240.798 1.602-52.048 19.716-100.957 45.754-151.937 65.876-23.947 9.456-48.342 17.584-73.742 23.166-161.571 35.555-339.144-43.795-451.932-170.697-112.859-126.938-170.322-293.366-200.374-460.282"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint23_linear_141_34984)"
            d="M1550.27 1594.95c47.88-59 35.3-149.23-9.58-210.19-4.01-5.44-8.24-10.67-12.71-15.65-45.58-51.11-109.49-81.12-174.52-104.09-12.49-4.4-25.05-8.58-37.62-12.59-59.07-18.97-119.71-36.34-174.52-65.82-17.64-9.5-34.52-20.13-50.45-32.21-108.578-82.22-161.918-220.362-189.22-354.934a1419.077 1419.077 0 01-5.646-29.345c-26.217-144.412-41.702-289.944-87.846-417.605-22.882-63.292-51.367-130.045-112.105-165.415-72.693-42.407-167.337-27.958-245.026 2.55-52.882 20.777-102.451 48.199-154.233 69.561-24.311 10.022-49.105 18.682-74.975 24.757-164.502 38.519-346.098-42.023-460.714-171.446-114.65-129.495-172.212-299.459-202.261-469.552"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint24_linear_141_34984)"
            d="M1541.5 1613.94c47.92-58.89 35.94-148.9-8.26-210.35-3.94-5.47-8.12-10.77-12.53-15.79-45.03-51.6-108.54-82.43-173.31-106.21-12.45-4.55-24.98-8.88-37.5-13.06-58.86-19.64-119.31-37.59-173.93-67.35-17.57-9.57-34.41-20.27-50.31-32.42-108.323-82.69-161.129-220.747-187.356-355.555-1.918-9.806-3.692-19.61-5.395-29.378-24.894-144.574-37.229-290.567-80.562-420.193-21.856-65.387-50.279-134.352-111.859-170.911-73.747-43.848-170.331-28.565-249.219 3.534-53.681 21.839-103.945 50.645-156.528 73.247-24.711 10.624-49.905 19.814-76.246 26.348-167.431 41.446-353.051-40.216-469.493-172.196C-367.475-8.36-425.101-181.895-455.148-355.166"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint25_linear_141_34984)"
            d="M1532.74 1632.9c47.91-58.83 36.61-148.58-6.98-210.55a216.57 216.57 0 00-12.38-15.97c-44.53-52.13-107.6-83.78-172.15-108.34-12.37-4.72-24.86-9.16-37.35-13.49-58.63-20.31-118.93-38.83-173.38-68.87-17.49-9.64-34.26-20.45-50.12-32.6-108.07-83.11-160.34-221.165-185.529-356.175a1209.17 1209.17 0 01-5.144-29.41c-23.57-144.737-32.72-291.155-73.241-422.818-20.759-67.515-49.155-138.622-111.615-176.37-74.801-45.289-173.324-29.173-253.41 4.483-54.516 22.899-105.476 53.125-158.86 76.931-25.075 11.191-50.705 20.947-77.48 27.94C44.74 352.07-144.902 269.217-263.207 134.68-381.512.142-439.202-176.964-469.245-353.412"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint26_linear_141_34984)"
            d="M1524 1651.86c47.95-58.72 37.25-148.28-5.65-210.75-3.83-5.58-7.91-10.95-12.2-16.11-43.99-52.66-106.65-85.09-170.98-110.43-12.34-4.87-24.79-9.48-37.2-13.92-58.41-20.99-118.56-40.11-172.79-70.4-17.42-9.74-34.16-20.59-49.94-32.81-107.816-83.53-159.551-221.546-183.665-356.793a1412.059 1412.059 0 01-4.893-29.478c-22.246-144.899-28.175-291.707-65.921-425.406-19.661-69.609-48.03-142.929-111.333-181.865-75.856-46.694-176.318-29.781-257.602 5.431-55.315 23.961-106.971 55.571-161.156 80.617-25.439 11.757-51.469 22.079-78.714 29.531-173.291 47.337-366.957-36.672-487.089-173.731C-395.264 8.651-453.018-171.99-483.059-351.616"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint27_linear_141_34984)"
            d="M1515.23 1670.82c47.95-58.65 37.92-147.96-4.33-210.95-3.79-5.61-7.79-11.05-12.02-16.25-43.48-53.15-105.71-86.4-169.81-112.56-12.3-5.01-24.67-9.76-37.05-14.38-58.19-21.66-118.15-41.35-172.2-71.93-17.35-9.81-34.05-20.73-49.79-33.02-107.563-83.96-158.726-221.964-181.802-357.413a1206.884 1206.884 0 01-4.642-29.511c-20.922-145.062-23.561-292.222-58.601-428.031-18.492-71.7-46.942-147.199-111.087-187.324-76.911-48.135-179.312-30.424-261.831 6.415-56.15 25.058-108.466 58.016-163.487 84.301-25.84 12.36-52.269 23.213-79.984 31.123-176.22 50.264-373.91-34.865-495.869-174.481-121.958-139.616-179.776-323.828-209.813-506.631"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint28_linear_141_34984)"
            d="M1506.46 1689.81c47.98-58.54 38.6-147.62-3.04-211.11-3.72-5.65-7.69-11.12-11.88-16.38-42.93-53.69-104.76-87.75-168.63-114.66-12.27-5.15-24.61-10.08-36.91-14.8-58-22.31-117.78-42.6-171.61-73.46-17.28-9.92-33.9-20.87-49.61-33.24-107.271-84.42-157.935-222.34-179.935-358.03a1231.101 1231.101 0 01-4.392-29.543c-19.598-145.225-18.91-292.701-51.281-430.62-17.323-73.793-45.817-151.506-110.842-192.819-77.965-49.576-182.305-31.032-266.022 7.364-56.984 26.118-109.96 60.461-165.783 87.986-26.204 12.926-53.068 24.345-81.218 32.714-179.151 53.228-380.863-33.093-504.649-175.23C-423.128 25.809-481.01-161.939-511.008-347.919"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint29_linear_141_34984)"
            d="M1497.73 1708.77c47.98-58.47 39.23-147.29-1.72-211.31-3.64-5.72-7.58-11.23-11.69-16.52-42.4-54.22-103.82-89.06-167.44-116.79-12.19-5.32-24.49-10.37-36.79-15.26-57.78-22.99-117.41-43.88-171.02-74.99-17.21-9.99-33.79-21.01-49.43-33.45-107.016-84.84-157.143-222.76-178.07-358.651a1073.942 1073.942 0 01-4.141-29.576c-18.274-145.387-14.224-293.144-43.96-433.244-16.083-75.848-44.694-155.776-110.561-198.278-79.019-50.981-185.299-31.639-270.214 8.313-57.783 27.179-111.455 62.906-168.079 91.706-26.604 13.493-53.833 25.479-82.452 34.306-182.081 56.155-387.817-31.285-513.43-175.98-125.612-144.694-183.558-336.013-213.554-525.171"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint30_linear_141_34984)"
            d="M1488.95 1727.73c47.98-58.36 39.92-146.96-.42-211.51-3.61-5.75-7.44-11.33-11.55-16.67-41.89-54.74-102.87-90.4-166.27-118.87-12.15-5.47-24.42-10.65-36.64-15.69-57.56-23.66-117-45.13-170.43-76.52-17.17-10.06-33.65-21.15-49.29-33.62-106.757-85.27-156.349-223.15-176.236-359.276-1.452-9.907-2.725-19.777-3.891-29.644-16.95-145.585-9.538-293.551-36.639-435.833-14.844-77.904-43.57-160.082-110.316-203.773-80.074-52.422-188.293-32.247-274.406 9.261-58.618 28.241-112.95 65.387-170.411 95.392-26.968 14.095-54.632 26.611-83.722 35.896-185.01 59.083-394.77-29.513-522.21-176.729-127.439-147.252-185.484-342.106-215.442-534.44"
            opacity="0.54"
            ></motion.path>
            <motion.path
            stroke="url(#paint31_linear_141_34984)"
            d="M1480.18 1746.69c52.3-63.49 38.82-162.65-10.47-228.52-49.29-65.83-125.85-104.99-201.59-137.12-75.75-32.13-154.91-60.33-218.98-111.88-114.217-91.93-162.388-243.86-177.978-389.569-15.626-145.747-4.782-293.921-29.319-438.42-13.568-79.959-42.482-164.354-110.071-209.233-81.128-53.863-191.287-32.855-278.634 10.245-87.346 43.1-165.181 106.023-257.662 136.564-187.941 62.047-401.724-27.705-530.991-177.478-129.266-149.773-187.375-348.198-217.329-543.71"
            opacity="0.54"
            ></motion.path>
        </g>
        <defs>
            <linearGradient
            id="paint0_linear_141_34984"
            x1="-66.552"
            x2="1541.31"
            y1="-448.153"
            y2="1343.92"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint1_linear_141_34984"
            x1="-80.345"
            x2="1546.67"
            y1="-446.917"
            y2="1351.73"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint2_linear_141_34984"
            x1="-94.103"
            x2="1552"
            y1="-445.713"
            y2="1359.38"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint3_linear_141_34984"
            x1="-107.825"
            x2="1557.16"
            y1="-444.4"
            y2="1367"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint4_linear_141_34984"
            x1="-121.726"
            x2="1562.07"
            y1="-443.092"
            y2="1374.62"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint5_linear_141_34984"
            x1="-135.518"
            x2="1567.09"
            y1="-441.888"
            y2="1381.96"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint6_linear_141_34984"
            x1="-149.278"
            x2="1571.92"
            y1="-440.576"
            y2="1389.25"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint7_linear_141_34984"
            x1="-163"
            x2="1576.77"
            y1="-439.337"
            y2="1396.36"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint8_linear_141_34984"
            x1="-176.76"
            x2="1581.46"
            y1="-438.027"
            y2="1403.44"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint9_linear_141_34984"
            x1="-190.655"
            x2="1585.91"
            y1="-436.747"
            y2="1410.53"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint10_linear_141_34984"
            x1="-204.415"
            x2="1590.41"
            y1="-435.475"
            y2="1417.39"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint11_linear_141_34984"
            x1="-218.17"
            x2="1594.9"
            y1="-434.274"
            y2="1424.12"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint12_linear_141_34984"
            x1="-231.962"
            x2="1599.26"
            y1="-433.036"
            y2="1430.79"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint13_linear_141_34984"
            x1="-245.756"
            x2="1603.42"
            y1="-431.72"
            y2="1437.46"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint14_linear_141_34984"
            x1="-259.581"
            x2="1607.49"
            y1="-430.45"
            y2="1444.07"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint15_linear_141_34984"
            x1="-273.303"
            x2="1611.66"
            y1="-429.247"
            y2="1450.39"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint16_linear_141_34984"
            x1="-287.095"
            x2="1615.63"
            y1="-427.971"
            y2="1456.77"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint17_linear_141_34984"
            x1="-300.887"
            x2="1619.4"
            y1="-426.62"
            y2="1463.16"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint18_linear_141_34984"
            x1="-314.643"
            x2="1623.24"
            y1="-425.385"
            y2="1469.36"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint19_linear_141_34984"
            x1="-328.504"
            x2="1626.92"
            y1="-424.184"
            y2="1475.52"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint20_linear_141_34984"
            x1="-342.261"
            x2="1630.56"
            y1="-422.869"
            y2="1481.64"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint21_linear_141_34984"
            x1="-356.052"
            x2="1634.07"
            y1="-421.593"
            y2="1487.67"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint22_linear_141_34984"
            x1="-369.774"
            x2="1637.61"
            y1="-420.353"
            y2="1493.58"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint23_linear_141_34984"
            x1="-383.565"
            x2="1641"
            y1="-419.114"
            y2="1499.46"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint24_linear_141_34984"
            x1="-397.39"
            x2="1644.24"
            y1="-417.805"
            y2="1505.39"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint25_linear_141_34984"
            x1="-411.317"
            x2="1647.38"
            y1="-416.608"
            y2="1511.24"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint26_linear_141_34984"
            x1="-424.967"
            x2="1650.67"
            y1="-415.366"
            y2="1516.85"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint27_linear_141_34984"
            x1="-438.827"
            x2="1653.69"
            y1="-414.127"
            y2="1522.58"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint28_linear_141_34984"
            x1="-452.583"
            x2="1656.66"
            y1="-412.777"
            y2="1528.27"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint29_linear_141_34984"
            x1="-466.232"
            x2="1659.72"
            y1="-411.538"
            y2="1533.71"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint30_linear_141_34984"
            x1="-480.163"
            x2="1662.41"
            y1="-410.263"
            y2="1539.4"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
            <linearGradient
            id="paint31_linear_141_34984"
            x1="-493.919"
            x2="1665.16"
            y1="-408.951"
            y2="1544.92"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#F2FAFF"></stop>
            <stop offset="0.229" stopColor="#E6F4F1"></stop>
            <stop offset="0.711" stopColor="#B5D5FE"></stop>
            <stop offset="1" stopColor="#0099FA"></stop>
            </linearGradient>
        </defs>
        </motion.svg>
    );
}
