export default [
    {
        id: 1,
        name: 'python',
        icon: 'python.png',
        iconDarkMode: 'pythonDM.png'
    },
    {
        id: 2,
        name: 'pandas',
        icon: 'pandas.png',
        iconDarkMode: 'pandasDM.png'
    },
    {
        id: 3,
        name: 'git',
        icon: 'git.png',
        iconDarkMode: 'gitDM.png'
    },
    {
        id: 4,
        name: 'reactjs',
        icon: 'reactjs.png',
        iconDarkMode: 'reactjsDM.png'
    },
    {
        id: 5,
        name: 'javascript',
        icon: 'js.png',
        iconDarkMode: 'jsDM.png'
    },
    {
        id: 6,
        name: 'html5',
        icon: 'html5.png',
        iconDarkMode: 'html5DM.png'
    },
    {
        id: 7,
        name: 'css',
        icon: 'css.png',
        iconDarkMode: 'cssDM.png'
    },
    {
        id: 8,
        name: 'bootstrap5',
        icon: 'bootstrap5.png',
        iconDarkMode: 'bootstrap5DM.png'
    },
    {
        id: 9,
        name: 'sass',
        icon: 'sass.png',
        iconDarkMode: 'sassDM.png'
    },
]